import { useState, useRef, useEffect } from 'react'    ;
import * as pdfjsLib from 'pdfjs-dist';
import './App.css';

// Initialize PDF.js worker
pdfjsLib.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjsLib.version}/build/pdf.worker.min.js`;

function App() {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [showPayload, setShowPayload] = useState(false);
  const [currentPayload, setCurrentPayload] = useState('');
  const fileInputRef = useRef(null);
  const [fileBase64, setFileBase64] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [audioBase64, setAudioBase64] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioPlayerRef = useRef(null);
  const [filePreview, setFilePreview] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [previewWidth, setPreviewWidth] = useState(200);
  const [isDragging, setIsDragging] = useState(false);
  const [leftPanelWidth, setLeftPanelWidth] = useState(50);
  const [isResizingPanel, setIsResizingPanel] = useState(false);
  const [currentPDFPage, setCurrentPDFPage] = useState(1);
  const [totalPDFPages, setTotalPDFPages] = useState(0);
  const [includeFile, setIncludeFile] = useState(true);
  const [systemPrompt, setSystemPrompt] = useState('');
  const [systemAudioBase64, setSystemAudioBase64] = useState(null);
  const [isSystemRecording, setIsSystemRecording] = useState(false);
  const [isSystemPlaying, setIsSystemPlaying] = useState(false);
  const systemMediaRecorderRef = useRef(null);
  const systemAudioChunksRef = useRef([]);
  const systemAudioPlayerRef = useRef(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const videoRef = useRef(null);
  const [isHtmlView, setIsHtmlView] = useState(true);

  // Function to convert file to BASE64
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // Remove the data URL prefix (e.g., "data:application/pdf;base64,")
        const base64String = reader.result.split(',')[1];
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const loadPDFInfo = async (url) => {
    try {
      const pdf = await pdfjsLib.getDocument(url).promise;
      setTotalPDFPages(pdf.numPages);
    } catch (error) {
      console.error('Error loading PDF:', error);
    }
  };

  const handleFileSelect = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.size > 10 * 1024 * 1024) {
      alert('File is too large. Please select a file smaller than 10MB.');
      return;
    }

    try {
      setFileType(file.type);
      const previewUrl = URL.createObjectURL(file);
      setFilePreview(previewUrl);
      
      if (file.type === 'application/pdf') {
        setCurrentPDFPage(1);
        await loadPDFInfo(previewUrl);
      }

      const base64Data = await convertToBase64(file);
      setFileBase64(base64Data);
      setSelectedFileName(file.name);
      setIncludeFile(true);
      
      localStorage.setItem('selectedFileName', file.name);
      localStorage.setItem('selectedFileBase64', base64Data);

    } catch (error) {
      console.error('Error:', error);
      alert(`Error processing file: ${error.message}`);
    }
  };

  const startRecording = async () => {
    try {
      // Clear previous audio data
      setAudioBase64(null);
      localStorage.removeItem('recordedAudioBase64');
      audioChunksRef.current = [];

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        const reader = new FileReader();
        reader.readAsDataURL(audioBlob);
        reader.onloadend = () => {
          const base64Audio = reader.result.split(',')[1];
          setAudioBase64(base64Audio);
        };
      };

      // Start recording immediately
      setIsRecording(true);
      mediaRecorderRef.current.start();

    } catch (error) {
      console.error('Error accessing microphone:', error);
      alert('Error accessing microphone. Please ensure you have granted microphone permissions.');
      setIsRecording(false);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());

      const waitForAudio = new Promise((resolve) => {
        mediaRecorderRef.current.onstop = async () => {
          const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
          const reader = new FileReader();
          reader.readAsDataURL(audioBlob);
          reader.onloadend = () => {
            const base64Audio = reader.result.split(',')[1];
            setAudioBase64(base64Audio);
            resolve(base64Audio);
          };
        };
      });

      waitForAudio.then(async (base64Audio) => {
        try {
          setIsLoading(true);
          const byteCharacters = atob(base64Audio);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const audioBlob = new Blob([byteArray], { type: 'audio/wav' });

          // Create form data
          const formData = new FormData();
          formData.append('audio', audioBlob, 'audio.wav');

          // Call backend API
          const response = await fetch('http://localhost:5000/api/transcribe', {
            method: 'POST',
            body: formData
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          insertTextAtCursor(data.transcription);
        } catch (error) {
          console.error('Error processing audio:', error);
          alert('Error processing audio: ' + error.message);
        } finally {
          setIsLoading(false);
        }
      });
    }
  };

  const handlePlayback = () => {
    if (isPlaying && audioPlayerRef.current) {
      // Stop playback
      audioPlayerRef.current.pause();
      audioPlayerRef.current = null;
      setIsPlaying(false);
      return;
    }

    if (audioBase64) {
      // Create a Blob from the base64 data
      const byteCharacters = atob(audioBase64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const audioBlob = new Blob([byteArray], { type: 'audio/wav' });

      // Create a URL for the Blob
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);

      // Store reference to audio player
      audioPlayerRef.current = audio;

      // Set up event handlers
      audio.onended = () => {
        setIsPlaying(false);
        URL.revokeObjectURL(audioUrl);
        audioPlayerRef.current = null;
      };

      audio.onpause = () => {
        setIsPlaying(false);
      };

      audio.onplay = () => {
        setIsPlaying(true);
      };

      // Play the audio
      audio.play().catch(error => {
        console.error('Error playing audio:', error);
        alert('Error playing audio');
        setIsPlaying(false);
        audioPlayerRef.current = null;
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create conversation history string
    const conversationHistory = messages
      .map(msg => `${msg.role === 'user' ? 'User' : 'Assistant'}: ${msg.content}`)
      .join('\n\n');

    // Create the API payload with correct generation config
    const payload = {
      contents: [
        {
          role: "user",
          parts: [
            {
              text: conversationHistory ? 
                `Previous conversation:\n${conversationHistory}\n\nUser: ${userInput || ""}` : 
                (userInput || "")
            },
            ...(fileBase64 && includeFile ? [{
              inlineData: {
                mimeType: fileType || 'application/pdf',
                data: fileBase64
              }
            }] : [])
          ]
        }
      ],
      generationConfig: {
        temperature: 1,
        topK: 40,
        topP: 0.95,
        maxOutputTokens: 8192
      }
    };

    // Store the payload in localStorage
    localStorage.setItem('lastPayload', JSON.stringify(payload, null, 2));

    const newMessages = [...messages, { 
      role: 'user', 
      content: userInput || "" 
    }];
    setMessages(newMessages);
    setIsLoading(true);

    try {
      setIsLoading(true);
      console.log('Sending request to:', 'http://daydaytalk.com:5000/api/chat');
      console.log('Payload:', JSON.stringify(payload, null, 2));

      const response = await fetch('http://daydaytalk.com:5000/api/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        mode: 'cors',
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Server responded with ${response.status}: ${errorText}`);
      }

      const data = await response.json();
      console.log('Response from server:', data);
      
      setMessages([...newMessages, { role: 'assistant', content: data.response }]);
    } catch (error) {
      console.error('Detailed fetch error:', error);
      setMessages([...newMessages, { 
        role: 'assistant', 
        content: `Error: ${error.message}\nPlease check the console for more details.`
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  // Update the insertTextAtCursor function
  const insertTextAtCursor = (text) => {
    const textarea = document.querySelector('textarea');
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;
    const before = userInput.substring(0, startPos);
    const after = userInput.substring(endPos);
    
    // Trim any trailing newlines from the transcribed text
    const trimmedText = text.trim();
    
    // Add a space before the text if we're not at the start and the previous character isn't a space
    const needsSpace = startPos > 0 && before.charAt(before.length - 1) !== ' ' ? ' ' : '';
    
    setUserInput(before + needsSpace + trimmedText + after);
    
    // Reset cursor position after the inserted text
    setTimeout(() => {
      const newPosition = startPos + needsSpace.length + trimmedText.length;
      textarea.selectionStart = newPosition;
      textarea.selectionEnd = newPosition;
      textarea.focus();
    }, 0);
  };

  // Modify the transcribeWithChatGPT function
  const transcribeWithChatGPT = async () => {
    if (!audioBase64) return;

    try {
      setIsLoading(true);
      const byteCharacters = atob(audioBase64);
      const byteArray = new Uint8Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteArray[i] = byteCharacters.charCodeAt(i);
      }
      const audioBlob = new Blob([byteArray], { type: 'audio/wav' });

      // Create form data
      const formData = new FormData();
      formData.append('audio', audioBlob, 'audio.wav');

      // Call backend API
      const response = await fetch('http://localhost:5000/api/transcribe', {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      insertTextAtCursor(data.transcription);
    } catch (error) {
      console.error('Error transcribing with ChatGPT:', error);
      alert('Error transcribing with ChatGPT: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Add function to handle sending edited payload
  const handleSendEditedPayload = async (editedPayload) => {
    try {
      setIsLoading(true);
      const parsedPayload = JSON.parse(editedPayload);
      
      const response = await fetch('http://localhost:5000/api/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(parsedPayload)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setMessages([...messages, { role: 'assistant', content: data.response }]);
      setShowPayload(false);
    } catch (error) {
      console.error('Error:', error);
      alert('Error sending payload: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Update the PayloadModal component
  const PayloadModal = () => {
    const [editedPayload, setEditedPayload] = useState(currentPayload);

    useEffect(() => {
      setEditedPayload(currentPayload);
    }, []);

    if (!showPayload) return null;

    return (
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
      }}>
        <div style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          width: '80%',
          maxWidth: '800px',
          maxHeight: '80vh',
          overflow: 'auto',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          gap: '15px'
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <h3 style={{ margin: 0, textAlign: 'left' }}>Request Payload</h3>
            <button
              onClick={() => setShowPayload(false)}
              style={{
                padding: '5px 10px',
                border: 'none',
                borderRadius: '4px',
                backgroundColor: '#f0f0f0',
                cursor: 'pointer'
              }}
            >
              ✕
            </button>
          </div>
          <div style={{
            backgroundColor: '#f5f5f5',
            padding: '10px',
            borderRadius: '4px',
            fontFamily: 'monospace',
            fontSize: '14px',
            color: '#666'
          }}>
            POST https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-002:generateContent
          </div>
          <textarea
            value={editedPayload}
            onChange={(e) => setEditedPayload(e.target.value)}
            style={{
              width: '100%',
              minHeight: '400px',
              padding: '10px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              fontFamily: 'monospace',
              fontSize: '14px',
              lineHeight: '1.4',
              resize: 'vertical',
              backgroundColor: '#f5f5f5'
            }}
          />
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px'
          }}>
            <button
              onClick={() => {
                try {
                  // Validate JSON before sending
                  JSON.parse(editedPayload);
                  handleSendEditedPayload(editedPayload);
                } catch (error) {
                  alert('Invalid JSON format: ' + error.message);
                }
              }}
              style={{
                padding: '8px 15px',
                backgroundColor: '#2e7d32',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                gap: '5px'
              }}
            >
              <span role="img" aria-label="send">📤</span>
              Send this payload
            </button>
          </div>
        </div>
      </div>
    );
  };

  // Add drag handlers
  const handleDragStart = (e) => {
    setIsDragging(true);
    e.preventDefault(); // Prevent default drag behavior
  };

  const handleDrag = (e) => {
    if (isDragging && e.clientX > 100 && e.clientX < 600) { // Set min and max width
      setPreviewWidth(e.clientX - 40); // Adjust for padding
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  // Add event listeners for drag
  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleDrag);
      document.addEventListener('mouseup', handleDragEnd);
    }
    return () => {
      document.removeEventListener('mousemove', handleDrag);
      document.removeEventListener('mouseup', handleDragEnd);
    };
  }, [isDragging]);

  // Update the cursor style when dragging
  useEffect(() => {
    if (isDragging) {
      document.body.style.cursor = 'col-resize';
    } else {
      document.body.style.cursor = 'default';
    }
    return () => {
      document.body.style.cursor = 'default';
    };
  }, [isDragging]);

  // Add panel resize handlers
  const handlePanelDragStart = (e) => {
    setIsResizingPanel(true);
    e.preventDefault();
  };

  const handlePanelDrag = (e) => {
    if (isResizingPanel) {
      const container = document.querySelector('.App');
      const containerRect = container.getBoundingClientRect();
      const percentage = ((e.clientX - containerRect.left) / containerRect.width) * 100;
      
      // Limit the panel width between 30% and 70%
      if (percentage >= 30 && percentage <= 70) {
        setLeftPanelWidth(percentage);
      }
    }
  };

  const handlePanelDragEnd = () => {
    setIsResizingPanel(false);
  };

  // Add to useEffect for panel resize events
  useEffect(() => {
    if (isResizingPanel) {
      document.addEventListener('mousemove', handlePanelDrag);
      document.addEventListener('mouseup', handlePanelDragEnd);
    }
    return () => {
      document.removeEventListener('mousemove', handlePanelDrag);
      document.removeEventListener('mouseup', handlePanelDragEnd);
    };
  }, [isResizingPanel]);

  // Update cursor style for panel resizing
  useEffect(() => {
    if (isResizingPanel) {
      document.body.style.cursor = 'col-resize';
    } else if (!isDragging) { // Only reset if not dragging file preview
      document.body.style.cursor = 'default';
    }
    return () => {
      if (!isDragging) document.body.style.cursor = 'default';
    };
  }, [isResizingPanel, isDragging]);

  // Add system prompt recording functions
  const startSystemRecording = async () => {
    try {
      setSystemAudioBase64(null);
      systemAudioChunksRef.current = [];

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      systemMediaRecorderRef.current = new MediaRecorder(stream);

      systemMediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          systemAudioChunksRef.current.push(event.data);
        }
      };

      systemMediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(systemAudioChunksRef.current, { type: 'audio/wav' });
        const reader = new FileReader();
        reader.readAsDataURL(audioBlob);
        reader.onloadend = () => {
          const base64Audio = reader.result.split(',')[1];
          setSystemAudioBase64(base64Audio);
        };
      };

      setIsSystemRecording(true);
      systemMediaRecorderRef.current.start();

    } catch (error) {
      console.error('Error accessing microphone:', error);
      alert('Error accessing microphone. Please ensure you have granted microphone permissions.');
      setIsSystemRecording(false);
    }
  };

  const stopSystemRecording = () => {
    if (systemMediaRecorderRef.current && isSystemRecording) {
      systemMediaRecorderRef.current.stop();
      setIsSystemRecording(false);
      systemMediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());

      const waitForAudio = new Promise((resolve) => {
        systemMediaRecorderRef.current.onstop = async () => {
          const audioBlob = new Blob(systemAudioChunksRef.current, { type: 'audio/wav' });
          const reader = new FileReader();
          reader.readAsDataURL(audioBlob);
          reader.onloadend = () => {
            const base64Audio = reader.result.split(',')[1];
            setSystemAudioBase64(base64Audio);
            resolve(base64Audio);
          };
        };
      });

      waitForAudio.then(async (base64Audio) => {
        try {
          setIsLoading(true);
          const byteCharacters = atob(base64Audio);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const audioBlob = new Blob([byteArray], { type: 'audio/wav' });

          // Create form data
          const formData = new FormData();
          formData.append('audio', audioBlob, 'audio.wav');

          // Call backend API
          const response = await fetch('http://localhost:5000/api/transcribe', {
            method: 'POST',
            body: formData
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          insertSystemTextAtCursor(data.transcription);
        } catch (error) {
          console.error('Error processing audio:', error);
          alert('Error processing audio: ' + error.message);
        } finally {
          setIsLoading(false);
        }
      });
    }
  };

  const handleSystemPlayback = () => {
    if (isSystemPlaying && systemAudioPlayerRef.current) {
      systemAudioPlayerRef.current.pause();
      systemAudioPlayerRef.current = null;
      setIsSystemPlaying(false);
      return;
    }

    if (systemAudioBase64) {
      const byteCharacters = atob(systemAudioBase64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const audioBlob = new Blob([byteArray], { type: 'audio/wav' });
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);

      systemAudioPlayerRef.current = audio;

      audio.onended = () => {
        setIsSystemPlaying(false);
        URL.revokeObjectURL(audioUrl);
        systemAudioPlayerRef.current = null;
      };

      audio.onpause = () => {
        setIsSystemPlaying(false);
      };

      audio.onplay = () => {
        setIsSystemPlaying(true);
      };

      audio.play().catch(error => {
        console.error('Error playing audio:', error);
        alert('Error playing audio');
        setIsSystemPlaying(false);
        systemAudioPlayerRef.current = null;
      });
    }
  };

  const insertSystemTextAtCursor = (text) => {
    const textarea = document.querySelector('#systemPrompt');
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;
    const before = systemPrompt.substring(0, startPos);
    const after = systemPrompt.substring(endPos);
    
    const trimmedText = text.trim();
    const needsSpace = startPos > 0 && before.charAt(before.length - 1) !== ' ' ? ' ' : '';
    
    setSystemPrompt(before + needsSpace + trimmedText + after);
    
    setTimeout(() => {
      const newPosition = startPos + needsSpace.length + trimmedText.length;
      textarea.selectionStart = newPosition;
      textarea.selectionEnd = newPosition;
      textarea.focus();
    }, 0);
  };

  // Add this function to handle showing local storage payload
  const showLocalStoragePayload = () => {
    const storedPayload = localStorage.getItem('lastPayload');
    if (storedPayload) {
      setCurrentPayload(storedPayload);
      setShowPayload(true);
    } else {
      alert('No stored payload found');
    }
  };

  const openCamera = async () => {
    try {
      // Check if mediaDevices is supported
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        throw new Error('Your browser does not support camera access. Please use a modern browser.');
      }

      // Check if we're on HTTPS or localhost
      if (window.location.protocol !== 'https:' && window.location.hostname !== 'localhost') {
        throw new Error('Camera access requires HTTPS. Please use a secure connection.');
      }

      // Check if running on iOS
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      
      let stream; // Declare stream variable
      const constraints = {
        video: isIOS ? 
          { facingMode: { exact: "environment" } } : // iOS specific
          { facingMode: "environment" }  // Other devices
      };

      try {
        stream = await navigator.mediaDevices.getUserMedia(constraints);
        // If environment camera fails, try any available camera
        if (!stream) {
          constraints.video = true;
          stream = await navigator.mediaDevices.getUserMedia(constraints);
        }
        
        setIsCameraOpen(true);
        
        // Create a video element for camera preview
        const videoElement = document.createElement('video');
        videoElement.srcObject = stream;
        videoElement.autoplay = true;
        videoElement.playsInline = true; // Important for iOS
        videoRef.current = videoElement;
        
        // Create a modal for camera preview
        const modal = document.createElement('div');
        modal.style.position = 'fixed';
        modal.style.top = '0';
        modal.style.left = '0';
        modal.style.width = '100%';
        modal.style.height = '100%';
        modal.style.backgroundColor = 'rgba(0,0,0,0.9)';
        modal.style.zIndex = '1000';
        modal.style.display = 'flex';
        modal.style.flexDirection = 'column';
        modal.style.alignItems = 'center';
        modal.style.justifyContent = 'center';
        
        // Add video preview with iOS specific styling
        videoElement.style.maxWidth = '100%';
        videoElement.style.maxHeight = '80vh';
        videoElement.style.objectFit = 'cover'; // Better for mobile
        modal.appendChild(videoElement);
        
        // Add capture button with mobile-friendly styling
        const captureButton = document.createElement('button');
        captureButton.textContent = 'Take Photo';
        captureButton.style.margin = '20px';
        captureButton.style.padding = '15px 30px'; // Larger touch target
        captureButton.style.backgroundColor = '#ffc107';
        captureButton.style.border = 'none';
        captureButton.style.borderRadius = '8px';
        captureButton.style.cursor = 'pointer';
        captureButton.style.fontSize = '16px'; // Larger text for mobile
        
        captureButton.onclick = () => {
          // Create canvas to capture image
          const canvas = document.createElement('canvas');
          canvas.width = videoElement.videoWidth;
          canvas.height = videoElement.videoHeight;
          canvas.getContext('2d').drawImage(videoElement, 0, 0);
          
          // Convert to base64
          const imageBase64 = canvas.toDataURL('image/jpeg').split(',')[1];
          
          // Store in state and localStorage
          setFileBase64(imageBase64);
          setFileType('image/jpeg');
          setFilePreview(canvas.toDataURL('image/jpeg'));
          setSelectedFileName('camera_photo.jpg');
          setIncludeFile(true);
          
          localStorage.setItem('selectedFileBase64', imageBase64);
          localStorage.setItem('selectedFileName', 'camera_photo.jpg');
          
          // Close camera
          closeCamera();
        };
        
        modal.appendChild(captureButton);
        
        // Add close button with mobile-friendly styling
        const closeButton = document.createElement('button');
        closeButton.textContent = '✕';
        closeButton.style.position = 'absolute';
        closeButton.style.top = '20px';
        closeButton.style.right = '20px';
        closeButton.style.backgroundColor = '#f0f0f0';
        closeButton.style.border = 'none';
        closeButton.style.borderRadius = '4px';
        closeButton.style.padding = '15px'; // Larger touch target
        closeButton.style.cursor = 'pointer';
        closeButton.style.fontSize = '20px'; // Larger text for mobile
        closeButton.onclick = closeCamera;
        
        modal.appendChild(closeButton);
        
        document.body.appendChild(modal);

        // Start video playback (required for iOS)
        try {
          await videoElement.play();
        } catch (err) {
          console.error('Error playing video:', err);
        }

      } catch (streamError) {
        // If environment camera fails, try any available camera
        constraints.video = true;
        stream = await navigator.mediaDevices.getUserMedia(constraints);
      }

    } catch (error) {
      console.error('Error accessing camera:', error);
      if (error.name === 'NotAllowedError') {
        alert('Camera access was denied. Please enable camera access in your browser settings.');
      } else if (error.name === 'NotFoundError') {
        alert('No camera found on your device.');
      } else if (error.name === 'NotSupportedError') {
        alert('Your browser does not support camera access. Please use a modern browser.');
      } else if (error.message) {
        alert(error.message);
      } else {
        alert('Error accessing camera. Please make sure you are using HTTPS and have granted camera permissions.');
      }
    }
  };

  const closeCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach(track => track.stop());
    }
    const modal = document.querySelector('div[style*="position: fixed"]');
    if (modal) {
      document.body.removeChild(modal);
    }
    setIsCameraOpen(false);
  };

  // Add cleanup effect
  useEffect(() => {
    return () => {
      if (isCameraOpen) {
        closeCamera();
      }
    };
  }, [isCameraOpen]);

  return (
    <div className="App" style={{ padding: '20px', backgroundColor: '#f0f0f0', minHeight: '100vh' }}>
      {/* Header */}
      <div style={{ 
        marginBottom: '20px', 
        display: 'flex',
        flexDirection: 'column',
        gap: '15px'
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <h1 style={{ margin: 0 }}>Gemini AI Chat</h1>
        </div>

        {/* File selection row */}
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '15px'
        }}>
          <input
            type="checkbox"
            checked={includeFile}
            onChange={(e) => setIncludeFile(e.target.checked)}
            style={{ cursor: 'pointer' }}
          />
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileSelect}
          />
          <button 
            onClick={() => fileInputRef.current.click()}
            style={{
              padding: '10px 20px',
              backgroundColor: '#ffc107',
              color: 'black',
              border: 'none',
              borderRadius: '8px',
              cursor: 'pointer'
            }}
          >
            Select File
          </button>
          <button 
            onClick={openCamera}
            style={{
              padding: '10px 20px',
              backgroundColor: '#ffc107',
              color: 'black',
              border: 'none',
              borderRadius: '8px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              gap: '5px'
            }}
          >
            <span role="img" aria-label="camera">📸</span>
            Camera
          </button>
          {selectedFileName && (
            <div style={{
              backgroundColor: 'white',
              padding: '8px 12px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              maxWidth: '200px',
              display: 'flex',
              alignItems: 'center',
              gap: '10px'
            }}>
              <span style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}>
                {selectedFileName}
              </span>
              <button
                onClick={() => {
                  setSelectedFileName(null);
                  setFileBase64(null);
                  setFileType(null);
                  setIncludeFile(false);
                  if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                  }
                }}
                style={{
                  background: 'none',
                  border: 'none',
                  padding: '0',
                  cursor: 'pointer',
                  color: '#ff0000',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '16px'
                }}
              >
                ✕
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Main content area - Update widths */}
      <div style={{ 
        display: 'flex', 
        gap: '0px',
        height: 'calc(100vh - 200px)'
      }}>
        {/* File Preview - Set to 33.33% */}
        <div style={{ 
          width: '33.33%',
          backgroundColor: 'white',
          borderRadius: '8px 0 0 8px',
          padding: '20px',
          display: filePreview ? 'block' : 'none'
        }}>
          {filePreview && (
            <div style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px'
            }}>
              <h3 style={{ margin: 0 }}>File Preview</h3>
              <div style={{
                flex: 1,
                overflow: 'auto'
              }}>
                {fileType?.startsWith('image/') ? (
                  <img 
                    src={filePreview}
                    alt="Selected file"
                    style={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'contain',
                      borderRadius: '4px'
                    }}
                  />
                ) : fileType === 'application/pdf' && (
                  <div style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px'
                  }}>
                    <iframe
                      src={`${filePreview}#page=${currentPDFPage}`}
                      title="PDF preview"
                      style={{
                        width: '100%',
                        height: '100%',
                        border: '1px solid #ccc',
                        borderRadius: '4px'
                      }}
                    />
                    {totalPDFPages > 1 && (
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px'
                      }}>
                        <button
                          onClick={() => setCurrentPDFPage(prev => Math.max(1, prev - 1))}
                          disabled={currentPDFPage === 1}
                          style={{
                            padding: '5px 10px',
                            backgroundColor: '#f0f0f0',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            cursor: currentPDFPage === 1 ? 'default' : 'pointer',
                            opacity: currentPDFPage === 1 ? 0.5 : 1
                          }}
                        >
                          ←
                        </button>
                        <span>
                          Page {currentPDFPage} of {totalPDFPages}
                        </span>
                        <button
                          onClick={() => setCurrentPDFPage(prev => Math.min(totalPDFPages, prev + 1))}
                          disabled={currentPDFPage === totalPDFPages}
                          style={{
                            padding: '5px 10px',
                            backgroundColor: '#f0f0f0',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            cursor: currentPDFPage === totalPDFPages ? 'default' : 'pointer',
                            opacity: currentPDFPage === totalPDFPages ? 0.5 : 1
                          }}
                        >
                          →
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {/* Combined System and User Prompts - Set to 33.33% */}
        <div style={{ 
          width: '33.33%',
          backgroundColor: 'white',
          padding: '20px',
          borderLeft: '1px solid #ccc',
          borderRight: '1px solid #ccc',
          display: 'flex',
          flexDirection: 'column'
        }}>
          {/* System Prompt Section */}
          <div style={{
            marginBottom: '20px',
            display: 'flex',
            flexDirection: 'column',
            height: '40%'  // Take 40% of the height
          }}>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '15px'
            }}>
              <h2>System Prompt</h2>
              <button
                onClick={() => setSystemPrompt('')}
                style={{
                  padding: '8px 15px',
                  backgroundColor: '#f0f0f0',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px'
                }}
              >
                <span role="img" aria-label="clear">🗑️</span>
                Clear
              </button>
            </div>
            <div style={{ 
              display: 'flex', 
              gap: '10px',
              flex: 1
            }}>
              <textarea
                id="systemPrompt"
                value={systemPrompt}
                onChange={(e) => setSystemPrompt(e.target.value)}
                placeholder="Type system prompt here..."
                style={{
                  flex: 1,
                  padding: '10px',
                  borderRadius: '8px',
                  border: '1px solid #ccc',
                  resize: 'none'
                }}
              />
              <div style={{ 
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                justifyContent: 'flex-start'
              }}>
                <button
                  type="button"
                  onClick={isSystemRecording ? stopSystemRecording : startSystemRecording}
                  style={{
                    padding: '8px 15px',
                    backgroundColor: isSystemRecording ? '#d32f2f' : '#ffc107',
                    color: isSystemRecording ? 'white' : 'black',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    whiteSpace: 'nowrap'
                  }}
                >
                  <span role="img" aria-label="microphone">
                    {isSystemRecording ? '⏹️' : '🎤'}
                  </span>
                  {isSystemRecording ? 'Stop' : 'Speak'}
                </button>
                {systemAudioBase64 && (
                  <>
                    <button
                      type="button"
                      onClick={handleSystemPlayback}
                      style={{
                        padding: '8px 15px',
                        backgroundColor: '#ffc107',
                        color: 'black',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      <span role="img" aria-label="play">
                        {isSystemPlaying ? '⏹️' : '▶️'}
                      </span>
                      {isSystemPlaying ? 'Stop' : 'Play'}
                    </button>
                    <button
                      type="button"
                      onClick={() => transcribeWithChatGPT(systemAudioBase64, insertSystemTextAtCursor)}
                      disabled={isLoading}
                      style={{
                        padding: '8px 15px',
                        backgroundColor: '#ffc107',
                        color: 'black',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                        whiteSpace: 'nowrap',
                        opacity: isLoading ? '0.5' : '1'
                      }}
                    >
                      <span role="img" aria-label="transcribe">🤖</span>
                      Audio to Prompt
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* User Prompt Section */}
          <div style={{
            flex: 1,  // Take remaining space
            display: 'flex',
            flexDirection: 'column'
          }}>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '15px'
            }}>
              <h2>User Prompt</h2>
              <div style={{ display: 'flex', gap: '10px' }}>
                <button
                  onClick={() => setUserInput('')}
                  style={{
                    padding: '8px 15px',
                    backgroundColor: '#f0f0f0',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px'
                  }}
                >
                  <span role="img" aria-label="clear">🗑️</span>
                  Clear
                </button>
                <button
                  onClick={showLocalStoragePayload}
                  style={{
                    padding: '8px 15px',
                    backgroundColor: '#f0f0f0',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px'
                  }}
                >
                  <span role="img" aria-label="storage">💾</span>
                  Show Payload
                </button>
              </div>
            </div>
            <form onSubmit={handleSubmit} style={{ 
              display: 'flex', 
              flexDirection: 'column',
              gap: '10px',
              flex: 1
            }}>
              <div style={{ 
                display: 'flex', 
                gap: '10px',
                flex: 1 
              }}>
                <textarea
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  placeholder="Type your message here..."
                  style={{
                    flex: 1,
                    padding: '10px',
                    borderRadius: '8px',
                    border: '1px solid #ccc',
                    resize: 'none'
                  }}
                />
                <div style={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  justifyContent: 'flex-start'
                }}>
                  <button
                    type="button"
                    onClick={isRecording ? stopRecording : startRecording}
                    style={{
                      padding: '8px 15px',
                      backgroundColor: isRecording ? '#d32f2f' : '#ffc107',
                      color: isRecording ? 'white' : 'black',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    <span role="img" aria-label="microphone">
                      {isRecording ? '⏹️' : '🎤'}
                    </span>
                    {isRecording ? 'Stop' : 'Speak'}
                  </button>
                  {audioBase64 && (
                    <>
                      <button
                        type="button"
                        onClick={handlePlayback}
                        style={{
                          padding: '8px 15px',
                          backgroundColor: '#ffc107',
                          color: 'black',
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        <span role="img" aria-label="play">
                          {isPlaying ? '⏹️' : '▶️'}
                        </span>
                        {isPlaying ? 'Stop' : 'Play'}
                      </button>
                      <button
                        type="button"
                        onClick={transcribeWithChatGPT}
                        disabled={isLoading}
                        style={{
                          padding: '8px 15px',
                          backgroundColor: '#ffc107',
                          color: 'black',
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                          whiteSpace: 'nowrap',
                          opacity: isLoading ? '0.5' : '1'
                        }}
                      >
                        <span role="img" aria-label="transcribe">🤖</span>
                        Audio to Prompt
                      </button>
                    </>
                  )}
                </div>
              </div>
              <div style={{
                position: 'sticky',
                bottom: 0,
                backgroundColor: 'white',
                paddingTop: '10px'
              }}>
                <button 
                  type="submit" 
                  disabled={isLoading}
                  style={{
                    padding: '10px 20px',
                    backgroundColor: '#2e7d32',
                    color: 'white',
                    border: 'none',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    width: '100%'
                  }}
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* Right panel - AI Response */}
        <div style={{ 
          flex: 1,
          backgroundColor: '#e0e0e0',
          borderRadius: '0 8px 8px 0',
          padding: '20px',
          overflowY: 'auto',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '15px'
          }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
              <h2>AI Response</h2>
              <div style={{
                display: 'flex',
                backgroundColor: '#f0f0f0',
                borderRadius: '4px',
                padding: '2px',
                border: '1px solid #ccc'
              }}>
                <button
                  onClick={() => setIsHtmlView(false)}
                  style={{
                    padding: '6px 12px',
                    backgroundColor: !isHtmlView ? '#fff' : 'transparent',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    color: !isHtmlView ? '#000' : '#666',
                    transition: 'all 0.3s'
                  }}
                >
                  Text
                </button>
                <button
                  onClick={() => setIsHtmlView(true)}
                  style={{
                    padding: '6px 12px',
                    backgroundColor: isHtmlView ? '#fff' : 'transparent',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    color: isHtmlView ? '#000' : '#666',
                    transition: 'all 0.3s'
                  }}
                >
                  HTML
                </button>
              </div>
            </div>
            <button
              onClick={() => setMessages([])}
              style={{
                padding: '8px 15px',
                backgroundColor: '#f0f0f0',
                border: '1px solid #ccc',
                borderRadius: '4px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                gap: '5px'
              }}
            >
              <span role="img" aria-label="clear">🗑️</span>
              Clear
            </button>
          </div>
          <div style={{ height: 'calc(100% - 50px)', overflowY: 'auto' }}>
            {messages.map((message, index) => (
              message.role === 'assistant' && (
                <div 
                  key={index} 
                  style={{
                    marginBottom: '10px',
                    padding: '10px',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    height: isHtmlView ? 'auto' : 'calc(100vh - 200px)'  // Full height for text view
                  }}
                >
                  {isHtmlView ? (
                    <div 
                      dangerouslySetInnerHTML={{ 
                        __html: message.content
                          .replace(/^```html\n/, '')  // Remove ```html at the start
                          .replace(/```$/, '')        // Remove closing ``` if present
                          .replace(/\n/g, '<br>')
                          .replace(/```(.*?)\n([\s\S]*?)```/g, (match, lang, code) => 
                            `<pre style="background-color: #f5f5f5; padding: 10px; border-radius: 4px; overflow-x: auto;">
                              <code>${code.trim()}</code>
                            </pre>`
                          )
                          .replace(/`(.*?)`/g, '<code style="background-color: #f5f5f5; padding: 2px 4px; border-radius: 3px;">$1</code>')
                          .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
                          .replace(/\*(.*?)\*/g, '<em>$1</em>')
                      }}
                      style={{
                        lineHeight: '1.5',
                        fontSize: '14px'
                      }}
                    />
                  ) : (
                    <textarea
                      value={message.content}
                      readOnly
                      style={{
                        width: '100%',
                        height: '100%',  // Use full height of parent
                        padding: '10px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        backgroundColor: '#fff',
                        fontSize: '14px',
                        lineHeight: '1.5',
                        resize: 'none',  // Disable resize since we're using fixed height
                        fontFamily: 'monospace',
                        overflowY: 'auto'  // Add scroll for overflow
                      }}
                    />
                  )}
                </div>
              )
            ))}
            {isLoading && <div>Loading...</div>}
          </div>
        </div>
      </div>

      {/* Render the payload modal */}
      <PayloadModal />
    </div>
  );
}

export default App;
